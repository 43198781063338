main {
  min-height: 80vh;
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.product-title {
  height: 2.5em; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  white-space: nowrap; /* Prevent wrapping */
}

table td,
table th {
  text-align: center;
}

.review {
  margin-top: 30px;
}

.review h2 {
  font-size: 24px;
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
}

.review button {
  margin-top: 10px;
}

.carousel-caption {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}


.image-container {
  position: relative;
  text-align: left;
  color: white; 
}

.image-text {
  font-size: 30px;
  position: absolute;
  bottom: 0;
  width: 50%;
  padding: 50px;
}

@media screen and (max-width: 1000px) {
  .image-text {
    font-size: 25px;
  }
}

@media screen and (max-width: 1000px) {
  .image-text {
    font-size: 20px;
    width: 75%;
  }
}

@media screen and (max-width: 500px) {
  .image-container {
    text-align: center;
  }
  .image-text {
    font-size: 15px;
    width: 100%;
    top: 30%;
  }
}
